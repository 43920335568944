import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared/shared.module";
import { LearningAdminRoutingModule } from "./learning-admin-routing.module";
import { LearningAdminComponent } from "./learning-admin.component";
import { ActivityLogComponent } from "./activity-log/activity-log.component";
import { ModuleTrainingComponent } from "./module-training/module-training.component";
import { FunctionTrainingComponent } from "./function-training/function-training.component";
import { allIcons, NgxBootstrapIconsModule } from "ngx-bootstrap-icons";
import { AddEditModuleTrainingComponent } from "./add-edit-module-training/add-edit-module-training.component";
import { AddEditFunctionTrainingComponent } from "./add-edit-function-training/add-edit-function-training.component";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { TrainingService } from "./../service/training.service";
import { DepartmentMappingService } from "../service/departmentMapping.service";

@NgModule({
  declarations: [
    LearningAdminComponent,
    ActivityLogComponent,
    ModuleTrainingComponent,
    FunctionTrainingComponent,
    AddEditModuleTrainingComponent,
    AddEditFunctionTrainingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LearningAdminRoutingModule,
    NgxBootstrapIconsModule.pick(allIcons),
    
  ],
  providers: [TrainingService, ModuleFunctionService,DepartmentMappingService],
})
export class LearningAdminModule {}
