import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Constants, trainingStatus } from "src/app/constant/constants";
import { Role } from "src/app/models/role-model";
import { TrainingService } from "src/app/service/training.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { UtilityService } from "src/app/service/utility.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-module-training",
  templateUrl: "./module-training.component.html",
  styleUrls: ["./module-training.component.scss"],
})
export class ModuleTrainingComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;

  moduleTrainingList: any = [];
  role: number;
  roleEnum = Role;
  selected = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  moduleTraining: any;
  rowsOnPage = Constants.ROWS_ON_PAGE;
  modalRef: BsModalRef;
  isConfirmed: boolean = false;
  deleteId: string;
  isActiveUser: boolean = false;

  constructor(
    private modalService: BsModalService,
    private trainingService: TrainingService,
    private toaster: ToastrService,
    private utilityService: UtilityService,
    private datePipe: DatePipe
  ) {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.columns = [
      "userName",
      "module.moduleName",
      "trainingDateShortString",
      "trainerName",
      "statusStr",
      "statusChangedDateShortString",
      "recordedBy.companyUsername",
    ];
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getModuleConfigList(this.page.pageNumber + 1);
  }

  getModuleConfigList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.trainingService.getModuleList(currentPage, this.isActiveUser).then(
        (res: any) => {
          if (res["Success"]) {
            this.moduleTrainingList = [];
            var AllmoduleTrainig = res.Data.results;
            AllmoduleTrainig.forEach(element => {
              var trainingData= element;
              var validity = trainingData.module.ValidityPeriod ? trainingData.module.ValidityPeriod : "";
              var validity_date = this.addDaysInDate(trainingData.trainingDate,validity);
              trainingData.ExpirationDate = this.datePipe.transform(validity_date,'MM/dd/yyyy');
              this.moduleTrainingList.push(trainingData);
            });
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.moduleTrainingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.moduleTrainingList = [];
        }
      );
    }
  }
  addDaysInDate(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  filterData() {
    this.trainingService
      .moduledataFilter(this.searchSort, this.isActiveUser)
      .then(
        (res) => {
          if (res["data"] && res["data"].length > 0) {
            var AllmoduleTrainig = res["data"];
            AllmoduleTrainig.forEach(element => {
              var trainingData= element;
              var validity = trainingData.module.ValidityPeriod ? trainingData.module.ValidityPeriod : "";
              var validity_date = this.addDaysInDate(trainingData.trainingDate,validity);
              trainingData.ExpirationDate = this.datePipe.transform(validity_date,'MM/dd/yyyy');
              this.moduleTrainingList.push(trainingData);
            });
            this.totalItems = res["recordsFiltered"];
          } else {
            this.moduleTrainingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.moduleTrainingList = [];
          this.totalItems = 0;
        }
      );
  }

  downloadFile() {
    const downloadMethod = this.trainingService.downloadModuleExcelFile(
      this.searchSort,
      this.isActiveUser
    );
    downloadMethod.then(
      (response) => {
        if (response["Success"]) {
          const a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.href = environment.BLOB_URL + response["Message"];
          a.download = response["Message"].split("/")[1];
          a.click();
        } else {
          this.toaster.error(response["Message"]);
        }
      },
      (err) => { }
    );
  }
  closeModal(e) {
    if (e) {
      console.log(e)
      this.page.pageNumber = 0;
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  displayActive(event) {
    if (event.target.checked) {
      this.isActiveUser = true;
      this.getModuleConfigList(this.page.pageNumber + 1);
    } else if (!event.target.checked) {
      this.isActiveUser = false;
      this.getModuleConfigList(this.page.pageNumber + 1);
    }
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.moduleTraining = data;
    } else {
      this.moduleTraining = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }

  onClickDelete(data) {
    this.deleteId = data.trainingId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.trainingService.deleteTraining(id).then(
      (res) => {
        if (res["Success"]) {
          this.toaster.success(Constants.TRAINING_DELETE_SUCCESS_MSG);
          this.setPage({ offset: 0 });
        } else {
          this.toaster.error(res["Message"]);
        }
      },
      (err) => { }
    );
  }
  sortCallback(sortInfo) {
    var columnName = sortInfo.sorts[0].prop;
    var columnDir = sortInfo.sorts[0].dir;
    if(columnName == 'ExpirationDate'){
      columnName = "trainingDateShortString"
    }
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = columnName;
    this.searchSort.Order[0].Dir = columnDir;
    this.filterData();
  }
  close(event) {
    this.isConfirmed = false;
  }
  openDocument(data) {
    window.open(environment.BLOB_URL + data.Path, "_blank");
  }
}
