import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/constant/constants";
import { Role } from "src/app/models/role-model";
import { DepartmentMappingService } from "src/app/service/departmentMapping.service";
import { ModuleFunctionService } from "src/app/service/moduleFunction.service";
import { TrainingService } from "src/app/service/training.service";
import { UtilityService } from "src/app/service/utility.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-function-training",
  templateUrl: "./function-training.component.html",
  styleUrls: ["./function-training.component.scss"],
})
export class FunctionTrainingComponent implements OnInit {
  @ViewChild("filterTextValue", { static: false }) filterTextValue;

  functionTrainingList = [];
  role: number;
  roleEnum = Role;
  selected = [];
  selectedRecords = [];
  allRowsSelected = [];
  page: any;
  filterValue = [];
  searchSort: any;
  totalItems: any;
  columns = [];
  rowsOnPage = Constants.ROWS_ON_PAGE;
  functionTraining: any;
  modalRef: BsModalRef;
  deleteId: string;
  isConfirmed: boolean;
  isActiveUser: boolean = false;
  departmentMappingList=[];
  moduleConfigList=[];

  constructor(
    private modalService: BsModalService,
    private trainingService: TrainingService,
    private toaster: ToastrService,
    private utilityService: UtilityService,
    private departmentMappingService : DepartmentMappingService,
        private moduleFunctionService: ModuleFunctionService,
  ) {
    this.role = Number(localStorage.getItem(Constants.ROLE));
    this.columns = [
      "userName",
      "Function.functionName",
      "trainingDateShortString",
      "trainerName",
      "statusStr",
      "statusChangedDateShortString",
      "recordedBy.companyUsername",
    ];
    this.page = {
      pageNumber: 0,
      size: this.rowsOnPage,
    };
    this.searchSort = {
      Page: this.page.pageNumber + 1,
      PageSize: Constants.ROWS_ON_PAGE,
      Columns: [],
      Search: {
        Value: "",
        ColumnNameList: [],
        Regex: "string",
      },
      Order: [
        {
          Column: 0,
          ColumnName: "",
          Dir: "asc",
        },
      ],
    };
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getModuleConfigList(this.page.pageNumber + 1);
    this.getdepartmentMappingList(this.page.pageNumber + 1);
    this.getModulesList(this.page.pageNumber + 1);
  }

  getModuleConfigList(currentPage) {
    if (!!this.filterTextValue && !!this.filterTextValue.nativeElement.value) {
      this.searchSort.Search.Value = this.filterTextValue.nativeElement.value;
      this.searchSort.Search.ColumnNameList = this.columns;
      this.searchSort.Page = currentPage;
      this.filterData();
    } else {
      this.trainingService.getFunctionList(currentPage, this.isActiveUser).then(
        (res: any) => {
          if (res["Success"]) {
            this.functionTrainingList = res.Data.results;
            this.totalItems = res.Data.totalNumberOfRecords;
          } else {
            this.functionTrainingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.functionTrainingList = [];
        }
      );
    }
  }
  getdepartmentMappingList(currentPage) {

      this.departmentMappingService.getDepartmentMappingList(currentPage).then(
        (res: any) => {
          if (res["Success"]) {
            this.departmentMappingList = res.Data.results;
          } else {
            this.departmentMappingList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.departmentMappingList = [];
        }
      );
  }
  updateFilter(event) {
    if (event.key !== "Tab") {
      if (!!event.target.value) {
        this.searchSort.Search.Value = event.target.value;
        this.searchSort.Search.ColumnNameList = this.columns;
        this.searchSort.Page = this.page.pageNumber + 1;
        this.filterData();
      } else {
        this.clear();
      }
    }
  }

  filterData() {
    this.trainingService.functionDatFilter(this.searchSort, this.isActiveUser).then(
      (res) => {
        if (res["data"] && res["data"].length > 0) {
          this.functionTrainingList = res["data"];
          this.totalItems = res["recordsFiltered"];
        } else {
          this.functionTrainingList = [];
          this.totalItems = 0;
        }
      },
      (err) => {
        this.functionTrainingList = [];
        this.totalItems = 0;
      }
    );
  }
  closeModal(e) {
    if (e) {
      this.setPage({ offset: 0 });
    }
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>, data: any) {
    if (data) {
      this.functionTraining = data;
    } else {
      this.functionTraining = null;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  clear() {
    this.searchSort.Search.Value = "";
    this.searchSort.Order[0].ColumnName = "";
    this.searchSort.Order[0].Dir = "asc";
    this.filterTextValue.nativeElement.value = "";
    this.setPage({ offset: 0 });
  }
  onClickDelete(data) {
    this.deleteId = data.trainingId;
    this.isConfirmed = true;
  }

  delete(id) {
    this.isConfirmed = false;
    this.trainingService.deleteTraining(id).then(res => {
      if (res['Success']) {
        this.toaster.success(Constants.TRAINING_DELETE_SUCCESS_MSG);
        this.setPage({ offset: 0 });
      } else { this.toaster.error(res['Message']); }
    }, err => { });
  }
  sortCallback(sortInfo) {
    var columnName = sortInfo.sorts[0].prop;
    var columnDir = sortInfo.sorts[0].dir;
    if(columnName == 'ExpirationDate'){
      columnName = "trainingDateShortString"
    }
    this.searchSort.Page = this.page.pageNumber + 1;
    this.searchSort.Order[0].ColumnName = columnName;
    this.searchSort.Order[0].Dir = columnDir;
    this.filterData();
  }
  downloadFile() {
    const downloadMethod = this.trainingService.downloadFunctionExcelFile(this.searchSort, this.isActiveUser)
    downloadMethod.then(response => {
      if (response['Success']) {
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = environment.BLOB_URL + response['Message'];
        a.download = response['Message'].split('/')[1];
        a.click();
      } else {
        this.toaster.error(response['Message']);
      }
    }, err => { });;
  }
  displayActive(event) {
    if (event.target.checked) {
      this.isActiveUser = true;
      this.getModuleConfigList(this.page.pageNumber + 1);
      this.getdepartmentMappingList(this.page.pageNumber + 1);
      this.getModulesList(this.page.pageNumber + 1);
    }
    else if (!event.target.checked) {
      this.isActiveUser = false;
      this.getModuleConfigList(this.page.pageNumber + 1);
      this.getdepartmentMappingList(this.page.pageNumber + 1);
      this.getModulesList(this.page.pageNumber + 1);
    }
  }
  close(event) {
    this.isConfirmed = false;
  }
  openDocument(data) {
    window.open(environment.BLOB_URL + data.Path, "_blank");
  }
  getMinimumExpiryDate(date,functionId){
    var moduleMapping = this.departmentMappingList.filter(data=> data.FunctionId == functionId);
    if(moduleMapping.length > 0){
      var dates = [];
      var meregedModulesIds = [];
      var modules = moduleMapping;
      modules.forEach(m => {
        var modulesList = m.modules;
        modulesList.forEach(id => {
          meregedModulesIds.push(id);
        });
        var validity = 0;
        meregedModulesIds.forEach(exp => {
          var modulesValidity = this.moduleConfigList.filter(val=> val.moduleId == Number(exp));
           validity += modulesValidity[0].ValidityPeriod;
        });
        dates.push(this.addDaysInDate(date,validity))
      });
      const minDate = new Date(Math.min.apply(null, dates));
      return minDate;
    }else{
      return new Date(date);
    }
  }
  addDaysInDate(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getModulesList(currentPage) {
      this.moduleFunctionService.getModuleConfigList(currentPage).then(
        (res: any) => {
          if (res["Success"]) {
            this.moduleConfigList = res.Data.results;
          } else {
            this.moduleConfigList = [];
            this.totalItems = 0;
          }
        },
        (err) => {
          this.moduleConfigList = [];
        }
      );
  }
  
}
