import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormService } from "../core/form.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Constants, ContentEnum } from "../constant/constants";
import { SignIn } from "../models/signIn.model";
import { CompanyService } from "../service/company.service";
import { AuthService } from "../core/auth/auth.service";
import { defaultRoutes } from "../core/auth/default-routes";
import { UtilityService } from "../service/utility.service";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Role } from "src/app/models/role-model";
import { AllowRoleLoginComponent } from "./allow-role-login/allow-role-login.component";
import { CaptchaService } from "../service/captcha.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public messageList: any = new SignIn();
  companyList = [];
  companyId: number;
  companyName = "";
  isShowCompany: boolean = true;
  appName = environment.APP_NAME;
  logoUrl = "";
  webUrl = "";
  coverMyWork = Constants.COVERMYWORK;
  covergeCatch = Constants.COVERAGECATCH;
  modalRef: BsModalRef;
  data: any;
  title = Constants.TERMS_CONDITION;
  contentType = ContentEnum;
  type: any;
  userFailedCount: number = 0;
  showCaptcha: boolean;
  sitekey: string;
  isMultiple: boolean = false;
  userNameByCompanyID : any;
  UserCompanyList: any;
  userCompanyName: any;
  childForm: FormGroup;
  userCompanyID: any;
  retCompanId: any;
  changeCompany: any = true;
  receivedFormUserName: any;
  isChangeCompany: boolean;
  constructor(
    private formService: FormService,
    private router: Router,
    private toaster: ToastrService,
    private companyService: CompanyService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService,
    private captchaService: CaptchaService
  ) {}

  async ngOnInit() {
    this.sitekey = Constants.RECAPTCHA_SITEKEY;
    if (
      window.location.hostname.split(".").length > 2 &&
      window.location.hostname.split(".")[0] !== "www"
    ) {
      this.companyName = window.location.hostname.split(".")[0];
    } else {
      this.route.queryParams.subscribe((params) => {
        if (!params.hasOwnProperty("company")) {
          this.router.navigate([""], { queryParams: { company: "" } });
        } else {
          this.companyName = params["company"];
        }
      });
    }
    this.loginForm = new FormGroup({
      companyId: new FormControl(!!this.companyId ? this.companyId : "", [
        Validators.required,
      ]),
      companyUserName: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
    this.retCompanId = localStorage.getItem("companyName");
    this.initializeMessages();

    this.authService.commonToken().then(async (res: any) => {
      if (res.Success) {
        localStorage.setItem(Constants.COMMONTOKEN, res.Data);
        this.getConfigration();
      }
    });
  }

  initializeMessages() {
    this.messageList.companyId = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_REQUIRED,
    };
    this.messageList.companyUserName = {
      required: Constants.VALIDATION_MSG.SIGN_UP.COMPANY_NAME_REQUIRED,
    };

    this.messageList.password = {
      required: Constants.VALIDATION_MSG.SIGN_UP.PASSWORD_REQUIRED,
    };
  }
  async getConfigration() {
    await this.authService.getConfiguration().then(
      async (response) => {
        if (response["Success"] && !!response["Data"]) {
          localStorage.setItem(
            Constants.LOGO_PATH,
            !!response["Data"].LogoURL
              ? `../../assets/images/${response["Data"].LogoURL}`
              : ""
          );
          localStorage.setItem(
            Constants.LOGIN_URL,
            !!response["Data"].LoginURL
              ? `../../assets/images/${response["Data"].LoginURL}`
              : ""
          );
          localStorage.setItem(Constants.WEB_URL, response["Data"].WebURL);
          localStorage.setItem(Constants.APP_NAME, response["Data"].AppName);
          this.appName = localStorage.getItem(Constants.APP_NAME);
          this.titleService.setTitle(this.appName);
          this.appName = localStorage.getItem(Constants.APP_NAME);
          this.logoUrl = localStorage.getItem(Constants.LOGO_PATH);
          this.webUrl = localStorage.getItem(Constants.WEB_URL);
          document.documentElement.style.setProperty(
            "--bgImg",
            `url(${localStorage.getItem(Constants.LOGIN_URL)})`
          );
        }
      },
      (err) => {}
    );
  }

  receiveFlag($event){
    this.changeCompany = $event;
  }


  async getCompanyListOnInit(){
    await this.companyService.getCompanyListWithOutPagination(false).then((res) => {
      if(res["Success"]){
        this.companyList = res["Data"];
        console.log(this.companyList);

        if (!!this.companyName) {
          const index = this.companyList.findIndex(
            (x) =>
              x.companyName.toLowerCase() === this.companyName.toLowerCase()
          );
          if (index !== -1) {
            this.companyId = this.companyList[index].companyId;
            this.isShowCompany = !!this.companyId ? false : true;
            this.loginForm.controls.companyId.setValue(this.companyId);
            sessionStorage.setItem(Constants.COMPANY_NAME, this.companyName);
          } else {
            this.isShowCompany = true;
            sessionStorage.clear();
          }
        } else {
          this.isShowCompany = true;
          sessionStorage.clear();
        }

        if(localStorage.getItem(Constants.APP_NAME) == "CoverageCatch") return;

        this.formService.markFormGroupTouched(this.childForm);
          if (this.childForm.invalid) {
            this.userFailedCount++;
            if (this.userFailedCount === 3) {
              this.userFailedCount = 0;
              this.captchaService.sendForCaptcha(true);
            }
            return;
          }
          this.showCaptcha = true;
          const userData =
            "username=" +
              encodeURIComponent(this.childForm.controls.companyUserName.value) +
              "&password=" +
              encodeURIComponent(this.childForm.controls.password.value) +
              "&grant_type=password" +
              "&companyId=" +
              this.childForm.controls.companyId.value
            this.loginApi(userData);
          } else {
            this.companyList = [];
            sessionStorage.clear();
          } 
    }, (error) => {
      this.companyList = [];
      sessionStorage.clear();
    })
  }

  getCompanyList(uname) {
    this.companyService.getUserCompanyList(uname).then(
      (res) => {
        if (res["Success"]) {
          let selectedUserName = localStorage.getItem(Constants.USERNAME);
          let selectedCompanyId = localStorage.getItem(Constants.RETURNING_COMPANYID);
          this.UserCompanyList = res["Data"];
          if(this.changeCompany == false){
            this.companyList = res["Data"];
          }
          if(res["Data"] == null){
            this.utilityService.showErrorToast(Constants.VALIDATION_MSG.USER_INVALID)
            return;
          }
          if(res["Data"].length > 1){
            if(selectedUserName == this.childForm.controls.companyUserName.value && selectedCompanyId != null){
              if(this.changeCompany == false){
                var userCompanyId = this.childForm.controls.companyId.value;
              }
              else{
                if(this.changeCompany == true && this.childForm.controls.companyId.value != null){
                  if(this.childForm.controls.companyId.value == ""){
                    userCompanyId = selectedCompanyId;
                  }
                  else{
                    userCompanyId = this.childForm.controls.companyId.value;
                  }
                }
                else{
                  userCompanyId = selectedCompanyId;
                }
              }
            }
            else if(selectedUserName == this.childForm.controls.companyUserName.value && selectedCompanyId == this.childForm.controls.companyId.value && this.changeCompany == false){
              userCompanyId = this.childForm.controls.companyId.value;
            }
            else{
              if(selectedCompanyId == null && selectedUserName == null){
                this.isChangeCompany= true;
              }
              else{
                this.isChangeCompany = false;
              }
              this.isMultiple = true;
              userCompanyId = this.childForm.controls.companyId.value;
            }
          }
          if(res["Data"].length == 1){
            this.isMultiple = false;
            userCompanyId = this.UserCompanyList[0].companyId;
          }
          if(this.childForm.invalid){
            if(this.childForm.controls.password.value == ""){
              this.isMultiple = false;
              this.childForm.controls.password.markAsDirty();
              this.childForm.controls.password.markAsTouched();
              return;
            }
          }

          this.formService.markFormGroupTouched(this.childForm);
          if (this.childForm.invalid) {
            this.userFailedCount++;
            if (this.userFailedCount === 3) {
              this.userFailedCount = 0;
              this.captchaService.sendForCaptcha(true);
            }
            return;
          }
          this.showCaptcha = true;
          const userData =
            "username=" +
              encodeURIComponent(this.childForm.controls.companyUserName.value) +
              "&password=" +
              encodeURIComponent(this.childForm.controls.password.value) +
              "&grant_type=password" +
              "&companyId=" +
              userCompanyId

          if(this.changeCompany == true) {
            this.loginApi(userData);
          }     
          
          this.companyList = res["Data"];
          if (!!this.companyName) {
            const index = this.companyList.findIndex(
              (x) =>
                x.companyName.toLowerCase() === this.companyName.toLowerCase()
            );
            if (index !== -1) {
              this.companyId = this.companyList[index].companyId;
              this.isShowCompany = !!this.companyId ? false : true;
              this.loginForm.controls.companyId.setValue(this.companyId);
              sessionStorage.setItem(Constants.COMPANY_NAME, this.companyName);
            } else {
              this.isShowCompany = true;
              sessionStorage.clear();
            }
          } else {
            this.isShowCompany = true;
            sessionStorage.clear();
          }
        } else {
          this.companyList = [];
          sessionStorage.clear();
        }
      },
      (err) => {
        this.companyList = [];
        sessionStorage.clear();
      }
    );
  }

  childSignIn(event) {
    this.loginForm = event;
    this.signIn();
  }

  signIn() {
    this.formService.markFormGroupTouched(this.loginForm);
    if (this.loginForm.invalid) {
      this.userFailedCount++;
      if (this.userFailedCount === 3) {
        this.showCaptcha = true;
      }
      return;
    }
    
    localStorage.setItem(Constants.COMPANY_NAME, this.loginForm.controls.companyId.value);
    const userData =
      "username=" +
      encodeURIComponent(this.loginForm.controls.companyUserName.value) +
      "&password=" +
      encodeURIComponent(this.loginForm.controls.password.value) +
      "&grant_type=password" +
      "&companyName=" +
      this.loginForm.controls.companyId.value;
    this.loginApi(userData);
  }

  loginApi(userData) {
    this.utilityService.showLoading();
    this.authService.login(userData).then(
      (res: any) => {
        if (res) {
          localStorage.setItem(Constants.TOKEN, res["access_token"]);
          this.authService.getUserData().then(
            (res) => {
              if (res["Success"]) {
                localStorage.setItem(Constants.USERNAME, res["Data"].userName);
                localStorage.setItem(
                  Constants.COMPANYID,
                  res["Data"].companyId
                );
                localStorage.setItem(Constants.ROLE, (!!res["Data"].isLoginRoles && res["Data"].isLoginRoles.length > 0 )? res["Data"].isLoginRoles : res["Data"].roleId);
                localStorage.setItem(Constants.USERID, res["Data"].userId);
                localStorage.setItem(Constants.SHIFTID, res["Data"].shiftId);
                localStorage.setItem(
                  Constants.DEPARTMENTID,
                  res["Data"].departmentId
                );
                localStorage.setItem(
                  Constants.LOCATIONID,
                  res["Data"].locationId
                );
                localStorage.setItem(
                  Constants.PRICINGID,
                  res["Data"].pricingId
                );
                localStorage.setItem(Constants.IS_SWAP, res["Data"].isSwap);
                localStorage.setItem(
                  Constants.IS_TRAINING,
                  res["Data"].isTraining
                );
                localStorage.setItem(
                  Constants.IS_TIME_TRACKING,
                  res["Data"].isTimeTracking
                );
                localStorage.setItem(
                  Constants.IS_TRANSFER,
                  res["Data"].isTransfer
                );
                localStorage.setItem(Constants.IS_VOT, res["Data"].isVot);
                localStorage.setItem(
                  Constants.IS_TERMS_UPDATE,
                  res["Data"].isTermsUpdated
                );
                localStorage.setItem(
                  Constants.IS_VTO,
                  !!res["Data"].isVto ? res["Data"].isVto : false
                );
                localStorage.setItem(
                  Constants.IS_TIME_OFF,
                  !!res["Data"].isTimeKeeping
                    ? res["Data"].isTimeKeeping
                    : false
                );
                localStorage.setItem(
                  Constants.IS_CALL_OFF,
                  !!res["Data"].isCallOff ? res["Data"].isCallOff : false
                );
                localStorage.setItem(
                  Constants.IS_EARLY_OUT,
                  !!res["Data"].isEarlyOut ? res["Data"].isEarlyOut : false
                );
                localStorage.setItem(
                  Constants.IS_LATE_IN,
                  !!res["Data"].isLateIn ? res["Data"].isLateIn : false
                );
                localStorage.setItem(
                  Constants.IS_FLEX_WORK,
                  !!res["Data"].isFlexWork ? res["Data"].isFlexWork : false
                );
                localStorage.setItem(
                  Constants.IS_CLOCKINOUT,
                  !!res["Data"].isCheckInOut ? res["Data"].isCheckInOut : false
                );
                localStorage.setItem(
                  Constants.IS_REVIEW,
                  !!res["Data"].isReview ? res["Data"].isReview : false
                );
                
                let userRoleId = localStorage.getItem("identifierUser");
                if (!!userRoleId && userRoleId !='null' && userRoleId != "undefined") {

                  if (userRoleId.toString() !== res['Data'].userId.toString()) {
                    localStorage.removeItem("roleId");
                  }
                }
                
                let userRole = localStorage.getItem("roleId");
                if (!!userRole && userRole !='null' && userRole != "undefined") {

                  if (res['Data'].isLoginRoles.indexOf(Number(userRole)) === -1) {
                    localStorage.removeItem("roleId");
                  }
                }
                localStorage.setItem(
                  Constants.IS_ALLOW_AS_USER,
                  !!res["Data"].isAllowAsAUser
                    ? res["Data"].isAllowAsAUser
                    : false
                );
                  this.userModalOrRedirect(
                    res["Data"].isAllowAsAUser,
                    res["Data"].roleId,
                    userData
                  );
              } else {
                this.userFailedCount++;
                if (this.userFailedCount === 3) {
                  if (this.companyName.toLowerCase() === "coveragecatch") {
                    this.showCaptcha = true;
                  } else {
                    this.userFailedCount = 0;
                    this.captchaService.sendForCaptcha(true);
                  }
                }
                this.utilityService.showErrorToast(res["Message"]);
              }
            },
            (err) => {}
          );
        } else {
          this.utilityService.showErrorToast(res["Message"]);
        }
      },
      (err) => {
        this.userFailedCount++;
        if (this.userFailedCount === 3) {
          if (this.companyName.toLowerCase() === "coveragecatch") {
            this.showCaptcha = true;
          } else {
            this.userFailedCount = 0;
            this.captchaService.sendForCaptcha(true);
          }
        }
        this.utilityService.showErrorToast(Constants.VALIDATION_MSG.CHECK_CREDENTIALS);
      }
    );
  }

  checkAgreeDisagree(event) {
    this.modalRef.hide();
  }
  captchaSolved(event) {
    if (event === null) this.userFailedCount = 3;
    else this.userFailedCount = 0;
    this.showCaptcha = false;
  }

  openTermsModal(template: TemplateRef<any>, data: any) {
    if (data === 1) {
      this.type = this.contentType.TNC;
      this.title = Constants.TERMS_CONDITION;
    } else {
      this.type = this.contentType.PrivacyPolicy;
      this.title = Constants.PRIVACY;
    }
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered modal-lg",
      backdrop: "static",
    });
  }
  redirectToStore(target) {
    if (target === "playStore")
      window.open(
        "https://play.google.com/store/apps/details?id=com.covermywork.stage",
        "_blank"
      );
    else if (target === "appStore")
      window.open(
        "https://apps.apple.com/us/app/covermywork/id1591215261?ign-mpt=uo%3D2",
        "_blank"
      );
  }
  openRoleModal(userData) {
    this.utilityService.hideLoading();
    this.modalRef = this.modalService.show(AllowRoleLoginComponent, {
      class: "modal-dialog-centered modal-md",
      backdrop: "static",
      initialState: userData,
    });
    this.modalRef.content.data = this.loginForm.value;
  }

  userModalOrRedirect(isAllowAsUser, role,userData) {
    let userRoles = localStorage.getItem(Constants.ROLE);
    let roleCount = userRoles.split(',').length;
    if (roleCount > 1) {
      this.openRoleModal(userData);
    } else {
      this.router.navigate([defaultRoutes[userRoles]]);
    }
  }
}
